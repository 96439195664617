import React from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";

import TeamItem from '../components/TeamItem';


const TeamContainer = ({languageSelect}) => {
    return (
        <StaticQuery
            query={graphql`
              query {
                contentfulTeam {
                  subtitle_ru
                  subtitle_en
                  subtitle_cn
                  desc_ru
                  desc_en
                  desc_cn
                  teamItems{
                    id
                    name_ru
                    name_en
                    position_ru
                    position_en
                    position_cn
                    img{
                        title
                       gatsbyImageData
                    }
                  }
                }
              }
            `}
            render={data => {

                const teamElements = data.contentfulTeam.teamItems.map(item => {
                    return (
                        <TeamItem key={item.id}
                                  name={languageSelect === 'cn' ? item["name_en"] : item["name_" + languageSelect]}
                                  position={item["position_" + languageSelect]}
                                  img={item.img}
                                  imgAlt={item.img.title}
                        />
                    );
                });

                return (
                    <section className="section section-team">
                        <div className="container">
                            <div className="section-head">
                                <h2 className="subtitle subtitle_lvl_two">
                                    {data.contentfulTeam["subtitle_" + languageSelect]}
                                </h2>
                                <div className="subtitle-desc">
                                    {data.contentfulTeam["desc_" + languageSelect]}
                                </div>


                            </div>

                            <div className="team">
                                {teamElements}
                            </div>
                        </div>
                    </section>
                );
            }}
        />

    );
};



let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(TeamContainer);
