import React from 'react';
import Img from "../../../components/Img";


const TeamItem = ({img, imgAlt, name, position}) => {
    return (
        <div className="team-item">

                <div className="team-item-img">
                    <Img fluid={img} alt={imgAlt} className="team-item-img__item"/>
                </div>
                <div className="team-item-content">
                    <div className="team-item__name">{name}</div>
                    <div className="team-item__position">“{position}”</div>
                </div>

        </div>
    );
};


export default TeamItem;
