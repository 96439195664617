import React from 'react';
import { connect } from 'react-redux';
import { graphql, StaticQuery } from 'gatsby';

import { renderRichText } from 'gatsby-source-contentful/rich-text'

import About from '../components/About';
import {JSON_Parse} from "../../../utils/FormatUtils";

const AboutContainer = ({ languageSelect }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulAbout {
            subtitle_ru
            subtitle_en
            subtitle_cn
            desc_ru
            desc_en
            desc_cn
            description_ru {
              raw
            }
            description_en {
              raw
            }
            description_cn {
              raw
            }
            description_two_ru {
              raw
            }
            description_two_en {
              raw
            }
            description_two_cn {
              raw
            }
            description_three_ru {
              raw
            }
            description_three_en {
              raw
            }
            description_three_cn {
              raw
            }
            video_youTube
            video {
              file {
                url
              }
            }
            videoHd {
              file {
                url
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <section className='section about-page'>
            <div className='about'>
              <About
                description={JSON_Parse(
                  data.contentfulAbout['description_' + languageSelect].raw
                )}
                descriptionTwo={JSON_Parse(
                  data.contentfulAbout['description_two_' + languageSelect].raw
                )}
                descriptionThree={JSON_Parse(
                  data.contentfulAbout['description_three_' + languageSelect].raw
                )}
                video={data.contentfulAbout.video.file.url}
                videoYoutubeView={
                  languageSelect === 'ru' || languageSelect === 'en'
                    ? true
                    : false
                }
                videoYoutubeUrl={
                  data.contentfulAbout.video_youTube &&
                  data.contentfulAbout.video_youTube
                }
                videoHd={data.contentfulAbout.videoHd.file.url}
                languageSelect={languageSelect}
              />
            </div>
          </section>
        );
      }}
    />
  );
};

let mapStateToProps = (state) => {
  return {
    languageSelect: state.languageSelect['languageSelect'],
  };
};

export default connect(mapStateToProps)(AboutContainer);
