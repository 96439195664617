import React, { useEffect, useState } from 'react';
import { BigPlayButton, ControlBar, Player } from 'video-react';
import Responsive from 'react-responsive';

import Team from '../../Team/containers/TeamContainer';

const Adp = (props) => <Responsive {...props} maxWidth={767} />;
const NotAdp = (props) => <Responsive {...props} minWidth={768} />;

const About = ({
  description,
  descriptionTwo,
  descriptionThree,
  video,
  videoYoutubeView,
  videoYoutubeUrl,
  videoHd,
  languageSelect,
}) => {
  const mainVideo = React.createRef();

  const [videoFullScreen, setVideoFullScreen] = useState(false);

  useEffect(() => {
    if (!videoYoutubeView) {
      mainVideo.current.subscribeToStateChange(handleStateChange.bind(this));
    }
  }, [videoYoutubeView]);

  const handleStateChange = (state, prevState) => {
    setVideoFullScreen(state.isFullscreen);
  };

  return (
    <>
      <div className='container'>
        <div className='about-text'>{description}</div>
      </div>
      <div className='about-video'>
        <div className='container'>
          <div className='frame about-video__frame'>
            <div className='frame-item frame-item_color_black frame-top'>
              <div className='frame-item__el frame-top__frame-item-el_left'></div>
              <div className='frame-item__el frame-top__frame-item-el_right'></div>
            </div>
            <div className='frame-item frame-item_color_black frame-bottom'>
              <div className='frame-item__el frame-bottom__frame-item-el_left'></div>
              <div className='frame-item__el frame-bottom__frame-item-el_right'></div>
            </div>
          </div>
          <Adp>
            {videoYoutubeView && videoYoutubeUrl ? (
              <iframe
                className={'about-video-youtube'}
                src={`${videoYoutubeUrl}?autoplay=1&showinfo=0&autohide=1&hl=${languageSelect}`}
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            ) : (
              <Player
                autoPlay
                src={video}
                muted={!videoFullScreen}
                ref={mainVideo}
              >
                <ControlBar
                  autoHide={true}
                  disableDefaultControls={true}
                  disableCompletely={true}
                  className='my-class'
                />
                <BigPlayButton position='center' />
              </Player>
            )}
          </Adp>
          <NotAdp>
            {videoYoutubeView && videoYoutubeUrl ? (
              <iframe
                className={'about-video-youtube'}
                src={`${videoYoutubeUrl}?autoplay=1&showinfo=0&autohide=1&hl=${languageSelect}`}
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            ) : (
              <Player
                muted={!videoFullScreen}
                loop
                autobuffer
                autoPlay
                src={videoHd}
                ref={mainVideo}
              >
                <ControlBar
                  autoHide={true}
                  disableDefaultControls={false}
                  disableCompletely={false}
                />
              </Player>
            )}
          </NotAdp>
        </div>
      </div>
      <div className='container'>
        <div className='about-text'>{descriptionTwo}</div>
      </div>
      <Team />
      <div className='container'>
        <div className='about-text'>{descriptionThree}</div>
      </div>
    </>
  );
};

export default About;
